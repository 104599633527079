import HeaderImg from "@/components/home/HeaderImg.vue";

export default {
	name: "Service",
	components: {
		HeaderImg
	},
	data: () => {
		return {
			teacherList: [
				{
					name: "李 树 婷",
					title:
						"原中国医学科学院肿瘤医院药物临床研究中心GCP中心办公室主要负责人",
					avatar: require("@/assets/teacher2.png"),
					info:
						"1996年开始从事抗肿瘤药临床研究（GCP）和伦理委员会工作，是我国最早实施GCP的人员之一。<br/>\
              2014年获得中华医学会科技进步成果一等奖，中国药学会科技一等奖，华夏医学科技一等奖，教育部科技成果一等奖，北京市科技进步二等奖等；<br/>\
              目前为DIA中国顾问委员会委员，ISPE中国区指导委员会委员，中国GCP联盟副理事长，IDMCG项目组执行主任等。"
				},
				{
					name: "刘 燕 飞",
					title: "复旦大学附属肿瘤医院国家药物临床试验机构办公室主任",
					avatar: require("@/assets/teacher4.png"),
					info:
						"2004年7月开始参与筹建复旦大学附属肿瘤医院药物临床试验机构办公室，具有丰富的临床试验管理经历和经验。<br/>\
              负责药物临床试验机构办公室日常管理、临床试验相关培训、研究护士&CRC管理、临床试验质量管理等，确保临床试验的开展符合GCP和CFDA要求。<br/>\
              2010年6月至9月至美国Memorial Sloan-Kettering Cancer Center 临床研究办公室专门学习了临床试验管理。"
				},
				{
					name: "曹 烨",
					title: "中山大学附属肿瘤医院国家药物临床试验机构办公室主任",
					avatar: require("@/assets/teacher3.png"),
					info:
						"肿瘤学博士。2012年2月－2014年1月期间赴美国哈佛大学医学院DF/HCC癌症研究中心进修学习临床研究管理。 <br />\
              广东省药学会药物临床试验专业委员会秘书长、青年委员会主任委员，中国药理学会药物临床试验专业委员会委员。<br/>\
              工作期间参与过1类I期、国内、国际多中心II-III期临床试验20余项。参与国家“863”、“十一五”科技攻关项目“抗肿瘤药物临床试验平台建设”，发表过多篇临床研究管理论文。"
				},
				{
					name: "阎 昭",
					title: "天津医科大学肿瘤医院国家药物临床试验机构 办公室主任",
					avatar: require("@/assets/teacher1.png"),
					info:
						"博士，主任药师，硕士生导师。<br />\
                天津医科大学肿瘤医院国家药物临床试验机构办公室主任、临床药理研究室主任、中国抗癌协会继教与科技服务部常务副部长、国家肿瘤临床医学研究中心GCP平台负责人、国家科技部“重大新药创制重大专项”十一·五、十二·五GCP平台课题负责人。<br/>\
                天津市医院协会临床试验管理专业委员会副主任委员，天津市市场和质量监督管理委员会药品审评专家。"
				},
				{
					name: "刘 晓 红",
					title: "北京肿瘤医院研究护士长",
					avatar: require("@/assets/teacher6.png"),
					info:
						"2006年硕士毕业于中国协和医科大学护理学院，成为北京肿瘤医院第一名研究护士。2012年被聘为国家药物临床试验机构研究护士护士长及I期临床病区护士长。<br/>\
                参加国际、国内多中心临床试验管理50余项。中华护理学会北京分会会员、国际研究护士协会（IACRN）会员。《中华现代护理杂志》特约审稿人。<br/>\
                2007年受邀在中国肿瘤年会（CCMO）做研究护士角色及地位的专题报告；2014年在IACRN年会上做中国研究护士管理模式探讨的报告。"
				},
				{
					name: "李 坤 艳",
					title: "湖南省肿瘤医院国家药物临床试验机构办公室主任",
					avatar: require("@/assets/teacher5.png"),
					info:
						"药理学博士、留美访问学者、副主任药师、湖南省肿瘤医院国家药物临床试验机构办公室主任、Ⅰ期试验负责人、国家GCP审评专家、全国GCP联盟肿瘤专业委员会委员；多部杂志审稿专家；荣获“中国药学会科学技术奖”等奖励九项，获国家外国专家局、湖南省科技厅等课题资助五项，主持及参与Ⅰ-Ⅲ期临床研究五十余项，发表科研论文三十余篇。（其中SCI 11篇），编专著三部，副主编著作一部。<br/>\
                特别擅长：新药及医疗器械临床研究、临床药理、药物分析。 "
				}
			]
		};
	}
};
